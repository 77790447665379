import classNames from 'classnames'
import { useEffect, useState } from 'react'
import styles from './_progressBar.module.scss'

interface ProgressBarProps {
  isFlat: boolean
  currentTime: number
  duration: number
}

const ProgressBarLinear: React.FC<ProgressBarProps> = ({
  isFlat,
  currentTime,
  duration
}) => {
  const initialProgress = (currentTime / duration) * 100 || 0
  const [progress, setProgress] = useState(initialProgress)

  useEffect(() => {
    setTimeout(() => {
      setProgress(100)
    }, 1000)
  }, [])

  const timeToEnd = duration - currentTime
  const progressWidth = {
    width: `${progress}%`,
    transition: `width ${timeToEnd}s linear`
  }
  return (
    <div
      className={classNames(styles.progressContainer, {
        [styles.isFlat]: isFlat
      })}
    >
      <div
        className={classNames(styles.progressFiller, {
          [styles.isFlat]: isFlat
        })}
        style={progressWidth}
      ></div>
    </div>
  )
}

export default ProgressBarLinear
