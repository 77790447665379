import { gql } from '../../__generated__/gql'

export const FAVORITE_SONG_TOGGLE_MUTATION = gql(`
  mutation favoriteSongToggle($songId: Int!) {
    favoriteSongToggle(input: { songId: $songId }) {
      song {
        ...songFields
      }
      user {
        ...userProfileFields
      }
    }
  }
`)
