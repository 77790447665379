import useHover from '../hooks/useHover'
import { RefObject, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import styles from '../../components/_song.module.scss'
import ResizeObserver from 'resize-observer-polyfill'

const TextScrollContainer = ({ children, isFlat }) => {
  const [titleHolderContainerRef, isSongTitleHovered] = useHover()
  const [isTitleHolderHovered, setIsTitleHolderHovered] =
    useState<boolean>(false)
  const [scrollWidth, setScrollWidth] = useState<number>(0)
  const [isOverflown, setIsOverflown] = useState<boolean>(false)
  const [animationStatus, setAnimationStatus] = useState('finish')

  const animationId = useRef(null)
  const position = useRef(0)
  const mousePosition = useRef('over')

  const isOverflownChecker = (element) => {
    if (element) {
      const { clientWidth, scrollWidth } = element
      return {
        isOverflowing:
          scrollWidth === clientWidth ? false : scrollWidth > clientWidth,
        scrollWidth: scrollWidth
      }
    }
  }

  const amIOverflown = () => {
    const isOverflownCheckerData = isOverflownChecker(
      titleHolderContainerRef.current
    )

    if (isOverflownCheckerData) {
      const { isOverflowing, scrollWidth } = isOverflownCheckerData
      setIsOverflown(isOverflowing)
      if (isOverflowing) {
        setScrollWidth(scrollWidth)
      }
    }
  }

  const animationHandler = () => {
    const animation = () => {
      if (!titleHolderContainerRef.current) return
      titleHolderContainerRef.current.style.transform = `translateX(calc(${position.current}px)`
      if (isFlat) {
        position.current -= 1.3
      } else {
        position.current -= 1
      }

      titleHolderContainerRef.current.addEventListener('mouseover', () => {
        mousePosition.current = 'over'
      })
      titleHolderContainerRef.current.addEventListener('mouseout', () => {
        mousePosition.current = 'out'
      })
      const finishPosition = scrollWidth * -0.5 - 25

      if (position.current > finishPosition) {
        animationId.current = requestAnimationFrame(animation)
      } else if (mousePosition.current === 'over') {
        position.current = 0
        requestAnimationFrame(animation)
      } else {
        titleHolderContainerRef.current.style.transform = `translateX(0px)`
        setIsTitleHolderHovered(false)
        setAnimationStatus('finish')
      }
    }

    if (isSongTitleHovered && isOverflown && animationStatus === 'finish') {
      setAnimationStatus('progress')
      requestAnimationFrame(animation)
    }
  }

  useEffect(() => {
    if (titleHolderContainerRef.current) {
      amIOverflown()
    }
  }, [titleHolderContainerRef, isOverflown])

  useEffect(() => {
    if (isSongTitleHovered) {
      animationHandler()
      setIsTitleHolderHovered(true)
    }
  }, [isSongTitleHovered])

  useEffect(() => {
    const handleResize = () => {
      if (animationStatus === 'progress' && titleHolderContainerRef.current) {
        cancelAnimationFrame(animationId.current)
        titleHolderContainerRef.current.style.transform = 'translateX(0px)'
        setIsTitleHolderHovered(false)
        setAnimationStatus('finish')
        setIsOverflown(false)
      }
      amIOverflown()
    }

    const resizeObserver = new ResizeObserver(handleResize)
    resizeObserver.observe(titleHolderContainerRef.current)

    return () => resizeObserver.disconnect()
  }, [])

  return (
    <div
      className={classNames({
        [styles.title]: true,
        [styles.isOverflown]: isOverflown,
        [styles.hasEllipsis]: !isTitleHolderHovered && isOverflown
      })}
    >
      <div
        className={styles.titleHolderContainer}
        ref={titleHolderContainerRef as RefObject<HTMLDivElement>}
        data-name="title-holder"
      >
        <div className={classNames(styles.titleHolder)}>{children}</div>
        <div className={classNames(styles.titleHolder)} aria-hidden={true}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default TextScrollContainer
