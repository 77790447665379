import { RefObject, useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { songPath } from '../utils/routeHelpers'
import AddToSetList from './AddToSetList'
import FavoriteSong from './FavoriteSong'
import Link from 'next/link'

import { t } from '../utils/i18n/index'
import styles from './_song.module.scss'
import { joinedArtistsStr } from '../utils/songs/artists'
import { useAuth } from '../utils/auth'
import useHover from '../utils/hooks/useHover'
import classNames from 'classnames'
import TextScrollContainer from '../utils/containers/TextScrollContainer'
import Image from 'next/legacy/image'
import { useRouter } from 'next/router'
import { PlayerContext } from '../utils/song_player/PlayerProvider'
import ProgressBarLinear from './ProgressBarLinear'
import { Song } from '../__generated__/graphql'

const SongComponent: React.FC<{
  song: Song
  isFlat?: boolean
  inList?: boolean
  displayEditButton?: boolean
  hasTextScroll?: boolean
  priority?: boolean
}> = ({
  song,
  isFlat = false,
  inList = false,
  displayEditButton = false,
  priority = false
}) => {
  const {
    playerState: { player, isLoading },
    playOrPause
  } = useContext(PlayerContext)

  const playerSong = { id: song.id, url: song.sampleUrl }
  const isActive = player.isActiveSong(playerSong)
  const isPaused = player.isPaused(playerSong)
  const isSongLoading = player.isActiveSong(playerSong) && isLoading
  const isSongPlaying = isActive && !isPaused && !isSongLoading
  const hasPlayButton = isPaused || !isActive
  const { currentUser, isAdmin } = useAuth()
  const [songRef, isSongHovered] = useHover()
  const albumCover = song.album.cover.medium
  const isDraft = song.status === 'draft'

  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)

  const router = useRouter()

  const songActions = (
    <>
      {currentUser && (
        <ul
          className={classNames(styles.songActions, {
            [styles.isDisabled]: isSongPlaying
          })}
        >
          <li>
            <AddToSetList song={song} />
          </li>
          <li>
            <FavoriteSong song={song} />
          </li>
        </ul>
      )}
    </>
  )

  const editButton = () => {
    if (isAdmin && displayEditButton) {
      return (
        <div
          className={classNames(styles.editButton, {
            [styles.isDisabled]: isSongPlaying
          })}
        >
          <a
            href={`${process.env.NEXT_PUBLIC_OLD_URL}/admin/songs/${song.id}/edit`}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </a>
        </div>
      )
    }
    return <></>
  }

  useEffect(() => {
    const stopPlay = () => {
      if (!hasPlayButton) {
        playOrPause(playerSong)
      }
    }
    router.events.on('routeChangeStart', stopPlay)

    return () => router.events.off('routeChangeStart', stopPlay)
  }, [])

  useEffect(() => {
    const audio = player.getAudio()
    if (isLoading) {
      audio.onloadedmetadata = () => {
        setDuration(audio?.duration)
        setCurrentTime(audio?.currentTime)
      }
    } else if (!isActive) {
      setDuration(0)
      setCurrentTime(0)
    } else {
      setDuration(audio?.duration)
      setCurrentTime(audio?.currentTime)
    }
  }, [isPaused, isLoading, isActive])

  return (
    <article
      ref={songRef as RefObject<HTMLElement>}
      className={classNames(styles.song, {
        [styles.flat]: isFlat,
        [styles.inList]: inList,
        [styles.isActive]: !hasPlayButton,
        [styles.isHovered]: !isDraft && isSongHovered
      })}
    >
      <div className={`songContainer ${styles.songContainer}`}>
        <figure>
          {!isFlat && (
            <div
              className={classNames('is-square', {
                [styles.draftSong]: isDraft
              })}
            >
              {isDraft ? (
                <>
                  <Link
                    href={songPath(song)}
                    prefetch={false}
                    className={styles.songLink}
                  >
                    <Image
                      src={albumCover}
                      alt={`${song.title} | ${joinedArtistsStr(song.artists)}`}
                      width={205}
                      height={205}
                      quality={50}
                      layout="responsive"
                      sizes="(max-width: 256px) 66px,
                      (max-width: 384px) 85px,
                      (max-width: 769px) 210px,
                      (max-width: 1024px) 256px,
                      (max-width: 1216px) 240px,
                      (max-width: 1408px) 153px,
                      205px"
                    />
                  </Link>
                  <div className={styles.editButton}>
                    <a
                      href={`${process.env.NEXT_PUBLIC_OLD_URL}/admin/songs/${song.id}/edit`}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </a>
                  </div>
                </>
              ) : (
                <>
                  <Link
                    href={songPath(song)}
                    prefetch={false}
                    className={classNames({
                      [styles.isDisabled]: isSongPlaying
                    })}
                  >
                    <Image
                      priority={priority}
                      src={albumCover}
                      alt={`${song.title} | ${joinedArtistsStr(song.artists)}`}
                      quality={50}
                      width={205}
                      height={205}
                      layout="responsive"
                      sizes="(max-width: 256px) 66px,
                      (max-width: 384px) 85px,
                      (max-width: 769px) 210px,
                      (max-width: 1024px) 256px,
                      (max-width: 1216px) 240px,
                      (max-width: 1408px) 153px,
                      205px"
                    />
                  </Link>
                  {songActions}
                  {editButton()}
                </>
              )}
            </div>
          )}

          {inList && (
            <div
              className={classNames('is-square', styles.songImage, {
                [styles.draftSong]: isDraft,
                [styles.listDraftSong]: isDraft
              })}
            >
              {isDraft ? (
                <a
                  className={styles.songLink}
                  href={`${process.env.NEXT_PUBLIC_OLD_URL}/admin/songs/${song.id}/edit`}
                >
                  <Image
                    src={albumCover}
                    alt={`${song.title} | ${joinedArtistsStr(song.artists)}`}
                    width={82}
                    height={82}
                    quality={50}
                  />
                </a>
              ) : (
                <div>
                  <Image
                    src={albumCover}
                    alt={`${song.title} | ${joinedArtistsStr(song.artists)}`}
                    width={82}
                    height={82}
                    quality={50}
                  />
                </div>
              )}
            </div>
          )}

          {!isDraft && (
            <figcaption>
              <button
                type="button"
                className={styles.play}
                onClick={() => {
                  playOrPause(playerSong)
                }}
              >
                {isSongPlaying && <div className={styles.blob}></div>}
                {playButton(isSongLoading, hasPlayButton)}
              </button>
            </figcaption>
          )}
          {!isFlat && isSongPlaying && (
            <ProgressBarLinear
              isFlat={false}
              currentTime={currentTime}
              duration={duration}
            />
          )}
        </figure>

        <Link
          href={songPath(song)}
          prefetch={false}
          className={classNames(styles.songTitleLink, {
            [styles.isDisabled]: isSongPlaying
          })}
        >
          {isFlat && (
            <div className={styles.songInfo}>
              <TextScrollContainer isFlat={isFlat}>
                <div>
                  <strong>{song.title}</strong>
                </div>
                <div>{joinedArtistsStr(song.artists)}</div>
              </TextScrollContainer>
              {inList && (
                <div className={styles.songDetails}>
                  <div>
                    <span>{t('KEY')}</span>
                    <span>{song.key}</span>
                  </div>
                  <div>
                    <span>{t('BPM')}</span>
                    <span>{song.bpm}</span>
                  </div>
                  <div>
                    <span>{t('TIME SIG')}</span>
                    <span>{song.timeSignature}</span>
                  </div>
                </div>
              )}
            </div>
          )}
          {!isFlat && (
            <>
              <TextScrollContainer isFlat={isFlat}>
                <strong>{song.title}</strong>
              </TextScrollContainer>

              <TextScrollContainer isFlat={isFlat}>
                <span>{joinedArtistsStr(song.artists)}</span>
              </TextScrollContainer>
            </>
          )}
        </Link>
        {(isFlat || inList) && songActions}
      </div>

      {isFlat && (
        <Link
          href={songPath(song)}
          prefetch={false}
          className={classNames(styles.learn, 'button', {
            [styles.isDisabled]: isSongPlaying
          })}
        >
          {t('Learn')}
        </Link>
      )}

      {isFlat && !inList && isSongPlaying && (
        <ProgressBarLinear
          isFlat={true}
          currentTime={currentTime}
          duration={duration}
        />
      )}
    </article>
  )
}

export const playButton = (isSongLoading: boolean, hasPlayButton: boolean) => {
  if (isSongLoading) return '...'
  if (hasPlayButton) {
    return <FontAwesomeIcon icon={faPlay} />
  } else {
    return <FontAwesomeIcon icon={faPause} />
  }
}

export default SongComponent
