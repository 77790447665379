import { useMutation } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'

import styles from './_song.module.scss'
import { FAVORITE_SONG_TOGGLE_MUTATION } from '../gql/mutations'
import { useAuth } from '../utils/auth'
import {
  Song,
  FavoriteSongToggleMutationPayload
} from '../__generated__/graphql'

const FavoriteSong: React.FC<{ song: Song }> = ({ song }) => {
  const { currentUserFavorites, setCurrentUser } = useAuth()
  const isFavorite = currentUserFavorites.has(song.id)
  const [toggleFavorite] = useMutation<
    { favoriteSongToggle: FavoriteSongToggleMutationPayload },
    { songId: number }
  >(FAVORITE_SONG_TOGGLE_MUTATION, {
    variables: { songId: song.id },
    update: (_, result) => {
      setCurrentUser(result.data.favoriteSongToggle.user)
    }
  })

  return (
    <button
      onClick={() => toggleFavorite()}
      className={`${styles.songActionButton} ${
        isFavorite && styles.isActiveButton
      }`}
      type="button"
    >
      <FontAwesomeIcon icon={faHeart} />
    </button>
  )
}

export default FavoriteSong
