import { Artist, Song } from '../../__generated__/graphql'
import { artistPath } from '../routeHelpers'

export default function artistsPaths(song: Song): JSX.Element[] {
  const map = new Map()
  const artists = []
  for (const artist of song.artists) {
    if (!map.has(artist.id)) {
      map.set(artist.id, true)
      artists.push(artist)
    }
  }
  return artists.map((artist, index) => (
    <span key={artist.id}>
      {index > 0 ? ', ' : ''}
      <a href={artistPath(artist)}>{artist.name}</a>
    </span>
  ))
}

const joinedArtistsStr = (artists: Artist[]): string => {
  return Array.from(new Set((artists || []).map((artist) => artist.name))).join(
    ', '
  )
}

export { joinedArtistsStr }
