import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import styles from './_song.module.scss'
import { ModalsContext } from '../utils/modals'
import { useContext } from 'react'
import { Song } from '../__generated__/graphql'

const AddToSetList: React.FC<{ song: Song }> = ({ song }) => {
  const { openModal, closeModal } = useContext(ModalsContext)

  const openAddToSetlistModal = () => {
    openModal({
      name: 'AddToSetlist',
      props: {
        toggleModal: closeModal,
        song
      },
      modalTitle: 'Add Song To Setlist'
    })
  }

  return (
    <>
      <button
        type="button"
        className={styles.songActionButton}
        onClick={openAddToSetlistModal}
      >
        <FontAwesomeIcon icon={faPlusCircle} />
      </button>
    </>
  )
}

export default AddToSetList
