import { useState, useRef, useEffect, RefObject } from 'react'

const useHover = (
  Ref?: RefObject<HTMLElement>
): [RefObject<HTMLElement>, boolean, any] => {
  const [value, setValue] = useState(false)
  const ref = Ref ? Ref : useRef<HTMLElement>(null)
  const handleMouseOver = () => setValue(true)
  const handleMouseOut = () => setValue(false)

  useEffect(() => {
    const node = ref.current
    if (node) {
      node.addEventListener('mouseover', handleMouseOver)
      node.addEventListener('mouseout', handleMouseOut)
      return () => {
        node.removeEventListener('mouseover', handleMouseOver)
        node.removeEventListener('mouseout', handleMouseOut)
      }
    }
  }, [ref.current])

  return [ref, value, setValue]
}

export default useHover
